import { Tooltip } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const CustomTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#FCF8F3',
		color: '#7e3e1b',
		maxWidth: "10vw",
		border: '2px solid #7e3e1b',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#7e3e1b',
	},
});