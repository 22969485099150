import React, { useState } from 'react';
import { Box, Modal, Typography, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomTooltip } from './CustomTooltip';
import "./Products.css";

const ProductRow = React.memo(({ product, index, color, identifier }) => {
	const [price, setPrice] = useState(product.price);

	const handlePriceChange = (e) => {
		setPrice(parseFloat(e.target.value || product.price))
	};

	const handleImageClick = () => {
		if (!product?.results) {
			window.open(`https://walmart.com/ip/${product.item_id}`);
		}
	};

	const currentPrice = price ?? product.price;
	const profitDifference = product.walmart_price - currentPrice;

	return (
		<tr style={{ height: '15vh' }}>
			<td className="data-row" style={{ width: "20%" }}>
				<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
					<div className="walmartImage">
						<p onClick={handleImageClick} style={{ textAlign: "center", cursor: "pointer", margin: 'auto' }}>Walmart</p>
						<img
							alt="product_image"
							className="hoverImageTable"
							loading="lazy"
							src={product?.image || "/assets/noimage.png"}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = "/assets/noimage.png";
							}}
							style={{
								borderRadius: "1vw",
								height: "4.5vw",
								width: "4.5vw",
								objectFit: "scale-down",
								position: "relative",
								zIndex: "5"
							}}
							onClick={handleImageClick}
						/>
					</div>
					<span style={{ overflow: "hidden", textOverflow: "ellipsis", width: "10vw", height: "10vh", textAlign: "center", fontSize: "77.5%" }}>
						{product.title && product.title.split(" | At")[0].slice(0, 20)}...{product.title.split(" | At")[0].slice(-25)}
						{product.title && <CustomTooltip
							arrow
							placement="top-end"
							title={<Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, zIndex: 99999 }}>{product.title.split(" | At")[0]}</Typography>}
						>
							<InfoIcon style={{ cursor: "default", fontSize: '75%' }} />
						</CustomTooltip>}
						<br />
						{identifier === "Barcode" && <>
							<span style={{
								padding: "0% .8%",
								borderRadius: "30px",
								color: "black",
								backgroundColor: color,
								position: "absolute",
								marginLeft: "-4.1%",
								zIndex: "8",
							}}>UPC: {product['Barcode'].replace("'", "")}</span>
						</>}
						{product.source && <span style={{ marginTop: "1.2%", padding: "0% .8%", borderRadius: "30px", color: "black", backgroundColor: 'lightgray', position: "absolute", marginLeft: "-4.1%", zIndex: "8" }}>Source: <a href={product.source} target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{product.source.replaceAll("www.", "").substring(0, 10)}{product.source.replaceAll("www.", "").length >= 10 ? '...' : ''}</a></span>}
					</span>
					{product.csv_image && <div className="sourceImage">
						<p style={{ textAlign: "center", maxWidth: "4.5vw", cursor: "pointer", margin: 'auto', }}>CSV</p>
						<img
							alt="product_image"
							className="hoverImageTable"
							loading="lazy"
							src={product.csv_image ? product.csv_image : "/assets/noimage.png"}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = "/assets/noimage.png";
							}}
							style={{
								borderRadius: "1vw",
								border: "5px solid rgba(126, 62, 27, 0.5)",
								height: "4.5vw",
								width: "4.5vw",
								objectFit: "scale-down",
								position: "relative",
								zIndex: "5"
							}}
						/>
					</div>}
				</div>
			</td>
			<td className="data-row" style={{ width: '20%' }}>
				<a href={`https://walmart.com/ip/${product.item_id}`} target="_blank" rel="noreferrer" style={{ textDecoration: "underline", color: "#7e3e1b", fontSize: 16 }}>
					{product.item_id}
				</a>
				<ContentCopyIcon
					style={{ cursor: "copy", marginTop: "-.6%", fontSize: "75%" }}
					onClick={() => navigator.clipboard.writeText(product.item_id)}
				/>
			</td>
			<td className="data-row" style={{ width: "15%" }}>
				<FormControl sx={{ m: 1 }} variant="standard">
					<InputLabel htmlFor={`${index}-price`} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Price</InputLabel>
					<Input
						id={`${index}-price`}
						size="small"
						type='number'
						style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}
						placeholder={`${product?.price}` || ""}
						startAdornment={<InputAdornment position="start" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>$</InputAdornment>}
						onChange={handlePriceChange}
					/>
				</FormControl>
			</td>
			<td className="data-row" style={{ width: "20%" }}>
				{(product.walmart_price && product.walmart_price > 0) ? `$${product.walmart_price.toFixed(2)}` : "N/A"}
				{product.used_bb && <><br /><span style={{ borderRadius: "30px", backgroundColor: "lightgreen", padding: "4% 10%", fontSize: "80%" }}>BuyBox</span></>}
			</td>
			<td className="data-row" style={{ width: "20%" }}>
				<p style={{ color: profitDifference < 0 ? '#B33B3B' : '#769358' }}>
					{profitDifference < 0 ? `-$${(profitDifference * -1).toFixed(2)}` : `+$${profitDifference.toFixed(2)}`}
				</p>
			</td>
		</tr>
	);
});

function SubTableModal(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<Modal open={props.open} onClose={handleClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '60vw',
					bgcolor: '#FCF8F3',
					borderRadius: 2,
					border: '4px solid rgba(126, 62, 27, 0.5)',
					boxShadow: 24,
					height: "70vh",
					overflow: "auto",
					p: 4,
					paddingX: 10,
					outline: 'none',
				}}
			>
				<table className="products-table" style={{ width: '100%' }}>
					<thead>
						<tr>
							<th>Product</th>
							<th>Item Id</th>
							<th>Price</th>
							<th>Price on Walmart</th>
							<th>Gross Profit</th>
						</tr>
					</thead>
					<tbody>
						{props.data.map((product, index) => (
							<ProductRow
								key={product.item_id}
								product={product}
								index={index}
								color={props.color}
								identifier={props.identifier}
							/>
						))}
					</tbody>
				</table>
			</Box>
		</Modal>
	);
}

export default SubTableModal;
