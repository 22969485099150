import { Box, Button, FormControl, Input, InputAdornment, InputLabel, Modal, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { getUser } from "../../utils/authService";

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#7e3e1b',
    maxWidth: "10vw",
    border: '2px solid #7e3e1b',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#7e3e1b',
  },
}));

const ScansTable = (props) => {
  const [data, setData] = useState([]);
  const [filename, setFilename] = useState("");
  const [openFilenameModal, setOpenFilenameModal] = useState(false);
  const [asc, setAsc] = useState(false);
  const [row, setRow] = useState({});

  useEffect(() => {
    setData(props.scanData);
    sortByDate();
  }, []);


  function parseLocaleDate(dateString) {
    const [datePart, timePart] = dateString.split(', '); // Split date and time

    let day, month, year;
    if (datePart.includes("/")) {
        [month, day, year] = datePart.split('/').map(Number); // MM/DD/YYYY
    } else {
        [day, month, year] = datePart.split('.').map(Number); // DD.MM.YYYY
    }

    const [hours, minutes, seconds] = timePart.replace(" PM", "").replace(" AM", "").split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  const sortByDate = () => {
    setAsc(!asc);
    const sortedData = [...props.scanData].sort((a, b) => {
      const dateA = parseLocaleDate(a.date);
      const dateB = parseLocaleDate(b.date);

      if (asc) {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setData(sortedData);
  };

  return (
    <>
      <TableContainer component={Paper} elevation={0} sx={{ bgcolor: 'background.paper', maxHeight: '50vh', }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="left" style={{ cursor: 'pointer', textDecoration: 'underline dotted', textUnderlineOffset: '4px' }} onClick={() => sortByDate()}>
                Date{asc ? <ArrowDownwardOutlinedIcon /> : <ArrowUpwardOutlinedIcon />}
              </TableCell>
              <TableCell align="left">Line Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row, index) => (
              <TableRow key={index}>
                <TableCell scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={`../../assets/${row.fileName.endsWith('.csv') ? 'csv' : 'xlsx'}.png`} width={'35vw'} />&nbsp;{row.fileName}
                </TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.lineCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ScansTable;