import { Stack, styled, Box, Button, Modal, Typography, Tooltip, tooltipClasses, FormControlLabel, Checkbox, Menu, MenuItem, LinearProgress, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DoneIcon from '@mui/icons-material/Done';
import ModalForSettings from "../ModalForSettings";
import { utils, writeFile } from 'xlsx';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Papa from 'papaparse';
import { calcRefFees, calculateWfs } from "../../utils/helpers";

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#7e3e1b',
    maxWidth: "10vw",
    border: '2px solid #7e3e1b',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#7e3e1b',
  },
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function WalmartFilterBar(props) {
  const [isFilter, setIsFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [modalSetting, setModalSetting] = useState("");
  const [openModalSetting, setOpenModalSetting] = useState(false);
  const [openMoreSettingsModal, setOpenMoreSettingsModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State to track menu visibility
  const [saveFiltersSlot, setSaveFiltersSlot] = useState(1);
  const [manualRender, setManualRender] = useState(15);
  const [vlookup, setVlookup] = useState("");
  const [vlookupModal, setVlookupModal] = useState(false);
  const [categories, setCategories] = useState(props.data.reduce((acc, { category }) => {
    if (!acc[category] && category) {
      acc[category] = false;
    }
    return acc;
  }, {}));

  useEffect(() => {
    setCategories(props.data.reduce((acc, { category }) => {
      if (!acc[category] && category) {
        acc[category] = false;
      }
      return acc;
    }, {}))
  }, [props.data])

  async function uploadCSV(file) {
    if (file.size > 10000000) {
      alert(
        "Your file is larger than 10MB. Please consider splitting the Excel file and sending multiple scan requests."
      );
    } else {
      setIsLoading(true);
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const fileName = file.name.toLowerCase();
          if (fileName.endsWith('.csv')) {
            const text = e.target.result;
            Papa.parse(text, {
              header: true,
              complete: (results) => {
                setVlookup(results.data);
              },
            });
          }
        };

        if (file.name.toLowerCase().endsWith('.csv')) {
          reader.readAsText(file);
        }
      }
      setIsLoading(false);
    }
  }

  const toggleCategory = (name) => {
    if (name === 'all') {
      const newSelectAll = !selectAll;
      setCategories((prevCategories) =>
        Object.fromEntries(
          Object.keys(prevCategories).map((key) => [key, newSelectAll])
        )
      );
      setSelectAll(newSelectAll); // Update the selectAll state
    } else {
      setCategories((prevCategories) => ({
        ...prevCategories,
        [name]: !prevCategories[name],
      }));
    }
  };

  const handleClickCategory = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleCloseCategory = () => {
    props.setFilters((filters) => ({
      ...filters,
      category: Object.keys(categories).sort().filter(key => categories[key] === true),
    }))
    if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
      let tempFilters = { ...props.filters };
      delete tempFilters['category'];
      props.setFilters(tempFilters);
    }

    setAnchorEl(null); // Close the menu
  };

  const exportToExcel = () => {
    let formedData = []
    props.data.forEach(product => {
      formedData.push({
        "1. Item Id": product.item_id,
        "2. UPC": '="' + (product.Barcode ? product.Barcode : product.upcs) + '"',
        "3. Name": product.title,
        "4. Category": product.category,
        "5. Supplier Price": product.price,
        "7. Walmart Price": product.walmart_price,
        "8. Referral Fees": calcRefFees(product.category, product.walmart_price, product.sub_category),
        "9. WFS Fees": calculateWfs(product?.title, product.height, product.width, product.length, product.weight, product.walmart_price, product.category),
        "10. Shipping Cost (Optional input)": product?.shpng_cost ?? "N/A",
        "11. Amount of Sellers": product.total_offers_count ?? 0,
        "12. Additional Costs": product.prep,
        "13. Profit": product.walmart_price - product.price - calcRefFees(product.category, product.walmart_price, product.sub_category) - calculateWfs(product?.title, product.height, product.width, product.length, product.weight, product.walmart_price, product.category) - (product?.prep ?? 0),
        "10. ROI": (((product.walmart_price - product.price - calcRefFees(product.category, product.walmart_price, product.sub_category) - calculateWfs(product?.title, product.height, product.width, product.length, product.weight, product.walmart_price, product.category) - (product?.prep ?? 0)) / (product.price + (product?.prep ?? 0))) * 100).toFixed(2),
        "11. Review Count": product.reviews < 0 ? "N/A" : product.reviews
      })
    });
    const ws = utils.json_to_sheet(formedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, 'Aniseller.csv');
  };

  const filterBtnStyle = {
    textTransform: 'none',
    fontFamily: 'RobotoSlab',
    color: '#7e3e1b',
    minWidth: '7vw',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderRadius: '20px 20px 10px 10px',
  }

  const hasProperty = (property) => {
    return props.filters && props.filters.hasOwnProperty(property)
  }

  return (
    <div className="filterBar">
      {isFilter && (
        <div className="products-table-container">
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" style={{ marginBottom: 15 }}>
            {/* Export button */}
            <Button
              variant="contained"
              onClick={() => {
                if (props.activePlanLevel !== "Pro Plan" && props.activePlanLevel !== "Master Plan") {
                  alert("Only Pro & Master Plan subscribers can access this feature!");
                } else {
                  exportToExcel();
                }
              }}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#7e3e1b',
                width: '7vw',
                border: '2px solid #FCF8F3',
              }}
            >
              Export <FileDownloadOutlinedIcon style={{ color: '#FCF8F3' }} />
            </Button>

            <Stack direction="row" spacing={0} sx={{ flexGrow: 1 }} style={{ justifyContent: 'center', marginLeft: "-8vw" }}>
              {/* Filter out if Amazon is one of the sellers */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!props.filters.hasOwnProperty("isWFS") || !props.filters.isWFS}
                    sx={{ color: '#7e3e1b', '&.Mui-checked': { color: '#7e3e1b' } }}
                    onChange={(e) =>
                      props.setFilters((filters) => ({
                        ...filters,
                        isWFS: filters.hasOwnProperty("isWFS") ? !filters.isWFS : true,
                      }))
                    }
                  />
                }
                label={
                  <span style={{ fontFamily: 'RobotoSlab' }}>
                    Include WFS Fees in Walmart Fees
                  </span>
                }
              />
            </Stack>
          </Stack>

          <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={4} sx={{ flexGrow: 1 }}>
              { /* Reset Filters */}
              <CostumTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'RobotoSlab', }}>Reset Filters</Typography>
                  </React.Fragment>
                }
              >
                <FilterAltOffIcon onClick={() => props.setFilters({ isWFS: true, })} style={{ fontSize: 36, cursor: "pointer" }} />
              </CostumTooltip>

              {/* Categories select */}
              <Box sx={{ fontSize: 13 }}>
                <Button
                  onClick={handleClickCategory}
                  style={{
                    ...filterBtnStyle,
                    borderBottomWidth: hasProperty("category") ? "3px" : "2px",
                    borderBottomColor: hasProperty("category") ? "#e07b53" : "#7e3e1b",
                    backgroundColor: hasProperty("category") ? "#fcf8f3" : "transparent",
                  }}
                >
                  <ArrowRightIcon />
                  <span>Category</span>
                </Button>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseCategory}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    maxHeight: 350, // Set a maximum height
                  },
                }}
              >
                <MenuItem onClick={() => toggleCategory('all')} style={{ display: 'flex', flexDirection: 'row', gap: '16px', listStyleType: 'none', padding: 0, cursor: 'pointer', }}>
                  <Box
                    component={selectAll ? DoneIcon : 'span'}
                    sx={{ width: 14, height: 14, flexShrink: 0, borderRadius: '3px', ml: 1, mt: '2px', }}
                    style={{ border: '1px solid #7e3e1b', color: '#7e3e1b', }}
                  />
                  <Box><span style={{ color: '#7e3e1b' }}>{selectAll ? 'Unselect All' : 'Select All'}</span></Box>
                </MenuItem>
                {Object.keys(categories).map((name, key) => (
                  <MenuItem key={key} onClick={() => toggleCategory(name)} style={{ display: 'flex', flexDirection: 'row', gap: '16px', listStyleType: 'none', padding: 0, cursor: 'pointer', }}>
                    <Box
                      component={categories[name] ? DoneIcon : 'span'}
                      sx={{ width: 14, height: 14, flexShrink: 0, borderRadius: '3px', ml: 1, mt: '2px', }}
                      style={{ border: '1px solid #7e3e1b', color: '#7e3e1b', }}
                    />
                    <Box><span style={{ color: '#7e3e1b' }}>{name}</span></Box>
                  </MenuItem>
                ))}
              </Menu>

              {/* Price */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("price"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    borderBottomWidth: hasProperty("price") ? "3px" : "2px",
                    borderBottomColor: hasProperty("price") ? "#e07b53" : "#7e3e1b",
                    backgroundColor: hasProperty("price") ? "#fcf8f3" : "transparent",
                  }}
                ><span>Price</span>
                </Button>
              </Box>

              {/* Profit */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("profit"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    borderBottomWidth: hasProperty("profit") ? "3px" : "2px",
                    borderBottomColor: hasProperty("profit") ? "#e07b53" : "#7e3e1b",
                    backgroundColor: hasProperty("profit") ? "#fcf8f3" : "transparent",
                  }}
                ><span>Profit</span>
                </Button>
              </Box>

              {/* ROI */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("roi"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    borderBottomWidth: hasProperty("roi") ? "3px" : "2px",
                    borderBottomColor: hasProperty("roi") ? "#e07b53" : "#7e3e1b",
                    backgroundColor: hasProperty("roi") ? "#fcf8f3" : "transparent",
                  }}
                ><span>ROI</span>
                </Button>
              </Box>

              {/* AZOffers */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("offers"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    borderBottomWidth: hasProperty("offers") ? "3px" : "2px",
                    borderBottomColor: hasProperty("offers") ? "#e07b53" : "#7e3e1b",
                    backgroundColor: hasProperty("offers") ? "#fcf8f3" : "transparent",
                  }}
                ><span>Amount of Sellers</span>
                </Button>
              </Box>

              {/* Review Count */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("reviews"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    borderBottomWidth: hasProperty("reviews") ? "3px" : "2px",
                    borderBottomColor: hasProperty("reviews") ? "#e07b53" : "#7e3e1b",
                    backgroundColor: hasProperty("reviews") ? "#fcf8f3" : "transparent",
                  }}
                ><span>Review Count</span>
                </Button>
              </Box>
            </Stack>

            {/* More... */}
            <Button
              variant="contained"
              onClick={() => setOpenMoreSettingsModal(true)}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#7e3e1b',
                width: '7vw',
                border: '2px solid #FCF8F3',
              }}
            ><span>More</span>
            </Button>
          </Stack>
        </div>
      )}

      {/* Settings Modal */}
      <ModalForSettings
        filters={props.filters}
        setFilters={props.setFilters}
        modalSetting={modalSetting}
        setModalSetting={setModalSetting}
        isMobile={props.isMobile}
        supplierType={"retail"}
        open={openModalSetting}
        setOpen={setOpenModalSetting}
      />

      {/* More Settings Modal */}
      <Modal open={openMoreSettingsModal} onClose={() => setOpenMoreSettingsModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Advanced Settings
          </Typography>

          <div style={{ border: "1px solid gray", paddingTop: "15px", paddingBottom: "15px", }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: "1em" }}>
              {Array.from({ length: 5 }).map((_, i) => (
                <button style={{ backgroundColor: "transparent", borderRadius: "50%", cursor: saveFiltersSlot !== (i + 1) && "pointer" }} disabled={saveFiltersSlot === (i + 1)} onClick={() => setSaveFiltersSlot(i + 1)}>{i + 1}</button>
              ))}
            </Box>

            <p style={{ width: "60%", overflowY: "scroll", backgroundColor: "rgba(0,0,0,0.2)", minHeight: manualRender + "px", maxHeight: "10vh", textAlign: "center", margin: "auto", marginTop: "5px" }}>
              {(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null ?
                localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) : "").replace("{", "").replace("}", "").replaceAll('"', '').replaceAll(',', ', ').replace("search:,", "").replace("moq:[-1, -1],", "").toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).replace(":", ": ")).join(' ').replaceAll("-1", "N/A")}
            </p>

            {/* Save & Load buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.setItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""), JSON.stringify(props.filters));
                  setManualRender(mr => mr + 1);
                }}
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#FCF8F3',
                  borderRadius: '20px',
                  backgroundColor: '#769358',
                  border: '1px solid #FCF8F3'
                }} >
                Save Filters
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null
                    && props.setFilters(JSON.parse(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""))));
                }}
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#4A4A4A',
                  borderRadius: '20px',
                  backgroundColor: '#F8DE7E',
                  border: '1px solid #4A4A4A'
                }} >
                Load Filters
              </Button>
            </Box>
          </div>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenMoreSettingsModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default WalmartFilterBar;
