import { Box, Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Menu, MenuItem, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import '../Products.css';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { addToFavoritesDB, calcRefFees, calculateWfs, removeFromFavoritesDB } from "../../utils/helpers";
import { CustomTooltip } from "../CustomTooltip";
import { useNavigate } from "react-router-dom";
import SubTableModal from "../SubTableModal";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Google } from "@mui/icons-material";
import { getUser } from "../../utils/authService";

const SortableTableHeader = (props) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let timer;
		if (isLoading) {
			timer = setTimeout(() => setIsLoading(false), 500); // Ensure loading state for at least 500ms
		}
		return () => clearTimeout(timer);
	}, [isLoading]);

	const handleSort = async () => {
		setIsLoading(true);
		const newSortASC = !props.filters.sortBy?.[1];

		// Perform sorting operation in the next tick to allow loading state to be rendered
		setTimeout(() => {
			props.setSortASC(newSortASC);
			props.setFilters((prevFilters) => ({
				...prevFilters,
				sortBy: [props.sortKey, newSortASC],
			}));
		}, 0);
	};

	return (
		<th className="sortColumn" onClick={handleSort}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
					{props.columnName}
					{isLoading ? (
						<CircularProgress size={20} thickness={5} style={{ marginLeft: '8px', color: 'inherit', }} />
					) : (
						props.filters.sortBy?.[0] === props.sortKey && (
							props.filters.sortBy[1] ? (
								<ArrowUpwardOutlinedIcon style={{ fontSize: '100%', fontWeight: 'bold', marginLeft: '4px' }} />
							) : (
								<ArrowDownwardOutlinedIcon style={{ fontSize: '100%', fontWeight: 'bold', marginLeft: '4px' }} />
							)
						)
					)}
				</div>
			</div>
		</th>
	);
};

function WalmartProductsTable(props) {
	const [page, setPage] = useState(1);
	const [prep, setPrep] = useState(localStorage.getItem("costPrep") || 0);
	const [shippingCost, setShippingCost] = useState(localStorage.getItem("shippingCost") || 0);
	const [barcodeColorMap, setBarcodeColorMap] = useState({});
	const [searchModal, setSearchModal] = useState({});
	const [openSearchModal, setOpenSearchModal] = useState(false);
	const [openSubTableModal, setOpenSubTableModal] = useState(false);
	const [favorites, setFavorites] = useState({});
	const [expandedProducts, setExpandedProducts] = useState({});
	const [subTableData, setSubTableData] = useState({});
	const [pricesList, setPricesList] = useState({});

	const toggleExpand = (itemId) => {
		if (!expandedProducts[itemId]) {
			setExpandedProducts((prevExpandedProducts) => ({ ...prevExpandedProducts, [itemId]: true }));
		} else {
			setExpandedProducts((prevExpandedProducts) => ({ ...prevExpandedProducts, [itemId]: !prevExpandedProducts[itemId] }));
		}
	};

	function handleChangePrepCost(cost) {
		setPrep(cost);
		localStorage.setItem("costPrep", cost);
	}

	function handleChangeShippingCost(cost) {
		setShippingCost(cost);
		localStorage.setItem("shippingCost", cost);
	}

	useEffect(() => {
		const generateLightColor = () => `rgb(${Math.floor(Math.random() * 128 + 128)},${Math.floor(Math.random() * 128 + 128)},${Math.floor(Math.random() * 128 + 128)})`;

		setBarcodeColorMap((prevBarcodeColorMap) => {
			if (props.identifier === "Barcode") {
				const newBarcodeColorMap = { ...prevBarcodeColorMap };
				props.data.forEach(({ Barcode }) => {
					if (!newBarcodeColorMap[Barcode]) {
						newBarcodeColorMap[Barcode] = generateLightColor();
					}
				});
				return newBarcodeColorMap;
			} else {
				return {};
			}
		});
	}, [props.data]);

	useEffect(() => {
		setPage(1);
	}, [props.filters]);

	useEffect(() => {
		if (!props.isProductsLoading && props.data && props.data.length > 0) {
			props.data.slice((page - 1) * 10, page * 10).forEach(prd => {
				if (props.vlookupObj.hasOwnProperty(prd.item_id)) {
					prd.price = props.vlookupObj[[prd.item_id]].price
					prd.source = props.vlookupObj[[prd.item_id]].source
				}
				if (prd.upcs) {
					if (prd.upcs.split(',').some(upc => props.vlookupObj.hasOwnProperty(upc.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")))) {
						const matchingUPC = prd.upcs.split(',')
							.map(upc => upc.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", ""))
							.find(upc => props.vlookupObj.hasOwnProperty(upc));
						if (matchingUPC) {
							prd.price = props.vlookupObj[[matchingUPC]].price.replace("$", "")
							prd.source = props.vlookupObj[[matchingUPC]].source.replace("$", "")
						}
					}
				}
			});
		}
		props.setVlookupLoading(false);
	}, [props.isProductsLoading, props.data, page]);

	const addToFavorites = (product) => {
		setFavorites(prev => {
			return {
				...prev,
				[product.item_id]: true
			};
		});

		addToFavoritesDB(product);
	};

	const removeFromFavorites = (product) => {
		setFavorites(prev => {
			const newFavorites = { ...prev };
			newFavorites[product.item_id] = false;
			return newFavorites;
		});

		removeFromFavoritesDB(product.item_id);
	};

	const handlePriceChange = (itemId, price) => {
		setPricesList(prev => ({ ...prev, [itemId]: price }));
	}

	const hasPropertyIsWFS = () => {
		if (props.filters && !props.filters.hasOwnProperty("isWFS")) return true;
		return props.filters && props.filters.hasOwnProperty("isWFS") && !props.filters.isWFS
	}

	const ProductRowDisplay = ({ product, index, isResult, prep, setPrep, price, setPrice, shippingCost, setShippingCost, setData }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const [localPrice, setLocalPrice] = useState(price);
		const [localShippingCost, setLocalShippingCost] = useState(shippingCost);
		const [wfsCost, setWfsCost] = useState(calculateWfs(product?.title, product.height, product.width, product.length, product.weight, product.walmart_price, product.category));
		const [loadingResults, setLoadingResults] = useState(false);

		const navigate = useNavigate();

		const refFees = useRef(calcRefFees(product.category, product.walmart_price, product.sub_category));

		const handleFetchResults = (barcode) => {
			if (loadingResults) return;

			setLoadingResults(true);

			setData((prevData) => prevData.map(item =>
				item["Barcode"] === barcode ? { ...item, isLoading: true } : item
			));

			const requestOptions = {
				method: "GET",
				mode: "cors",
				headers: {
					Accept: "application/json; charset=utf8",
					"Content-Type": "application/json; charset=utf8",
				},
			};

			fetch(`https://server.getmarter.com/result_completion_aniseller_walmart/${barcode}/${getUser()}`, requestOptions)
				.then((response) => response.json())
				.then((data) => {
					if (data === "No permissions") {
						navigate("/pricing");
						return;
					}

					setData((prevData) => prevData.map(item =>
						item["Barcode"] === barcode ? { ...item, ...data, isLoading: false } : item
					));
				})
				.catch((e) => {
					setData((prevData) => prevData.map(item =>
						item["Barcode"] === barcode ? { ...item, isLoading: false } : item
					));
				});
		}

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};

		const openWalmartProduct = () => {
			if (!product?.results) {
				window.open(`https://walmart.com/ip/${product.item_id}`);
			}
		};

		return <>
			<tr style={{ height: '15vh', backgroundColor: isResult && "rgba(0,0,0,0.04)" }}>
				<td className="data-row" style={{ width: '16vw' }}>
					<div style={{ display: "flex" }}>
						{isResult && <>
							<SubdirectoryArrowRightIcon sx={{ fontSize: "3vw" }} />
						</>}
						<div className="walmartImage">
							<div
								onClick={openWalmartProduct}
								style={{ textAlign: "center", cursor: "pointer", margin: 'auto', }}
							>
								Walmart
							</div>
							{product?.results ? <>
								<div
									className="result"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										borderRadius: "1vw",
										fontWeight: "bold",
										height: "4.5vw",
										width: "4.5vw",
										fontSize: "1vw",
										textAlign: "center",
										position: "relative",
										zIndex: "5",
										border: "1px solid rgba(126, 62, 27, 0.2)",
										userSelect: "none"
									}}>
									{product.title === "" ? "?" : <>{product.results.length}<br/>Results</>}
								</div>
							</> : <>
								<img
									alt="product_image"
									className="hoverImageTable"
									loading="lazy"
									src={product?.image || "/assets/noimage.png"}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = "/assets/noimage.png";
									}}
									style={{
										borderRadius: "1vw",
										height: "4.5vw",
										width: "4.5vw",
										objectFit: "scale-down",
										position: "relative",
										zIndex: "5"
									}}
									onClick={openWalmartProduct}
								/>
							</>}
						</div>
						<span style={{ overflow: "hidden", textOverflow: "ellipsis", width: "10vw", height: "10vh", padding: "9px", textAlign: "center", margin: "auto", fontSize: "77.5%", }}>
							{product?.title && product?.title !== null && product?.title.split(" | At")[0].slice(0, 20)}...{product?.title.split(" | At")[0].slice(-25)}
							{product?.title && product?.title !== null && <CustomTooltip
								arrow
								placement="top-end"
								title={<>
									<Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, zIndex: 99999 }}>{product.title.split(" | At")[0]}</Typography>
								</>}
							>
								<InfoIcon style={{ cursor: "default", fontSize: '75%' }} />
							</CustomTooltip>}
							<br />
							{props.identifier === "Barcode" && <>
								<span
									style={{
										padding: "0% .8%",
										borderRadius: "30px",
										color: "black",
										backgroundColor: barcodeColorMap[product['Barcode']],
										position: "absolute",
										marginLeft: "-4.1%",
										zIndex: "8",
									}}>UPC: {product['Barcode'].replace("'", "")}</span>
							</>}
							{product.source && <span style={{ marginTop: "1.2%", padding: "0% .8%", borderRadius: "30px", color: "black", backgroundColor: 'lightgray', position: "absolute", marginLeft: "-4.1%", zIndex: "8" }}>Source: <a href={product.source} target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{product.source.replaceAll("www.", "").substring(0, 10)}{product.source.replaceAll("www.", "").length >= 10 ? '...' : ''}</a></span>}
						</span>

						{product.csv_image && <div className="sourceImage">
							<p style={{ textAlign: "center", maxWidth: "4.5vw", cursor: "pointer", margin: 'auto', }}>CSV</p>
							<img
								alt="product_image"
								className="hoverImageTable"
								loading="lazy"
								src={product.csv_image ? product.csv_image : "/assets/noimage.png"}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = "/assets/noimage.png";
								}}
								style={{
									borderRadius: "1vw",
									border: "5px solid rgba(126, 62, 27, 0.5)",
									height: "4.5vw",
									width: "4.5vw",
									objectFit: "scale-down",
									position: "relative",
									zIndex: "5"
								}}
							/>
						</div>}
					</div>
				</td>
				{product?.results ? <>
					<td id="results" className="data-row" style={{ position: "relative" }} colSpan="100%">
						{(!loadingResults && !product.isLoading) && (product.title === "" ? "No exact match found using the barcode" : product.results.length < 1 ? "No results found." : `${product.results.length} results found!`)}
						{product.title === "" && <>
							{((product?.isLoading !== undefined && product?.isLoading) || loadingResults) ? <>
								Using our unique technology to find the correct WM match...<LinearProgress color="green" style={{margin: "1%"}}/>
							</> : <><br />
								<Button
									onClick={() => handleFetchResults(product["Barcode"])}
									disabled={loadingResults}
									style={{
										padding: "5px 15px",
										borderRadius: "50px",
										textTransform: 'none',
										fontFamily: 'RobotoSlab',
										color: "#7e3e1b",
										backgroundColor: "rgba(126, 62, 27, 0.2)",
										width: "auto",
										fontWeight: "700",
									}}>
									Click here to search for WM product matches using &nbsp;<span className="ai-text">AI</span>&thinsp;&nbsp;
									<SearchIcon />
								</Button>
							</>}
						</>}
						{product.results.length >= 1 && (<><br />
							<IconButton
								onClick={() => toggleExpand(product["Barcode"])}
								style={{
									padding: "5px 15px",
									borderRadius: "50px",
									textTransform: 'none',
									fontFamily: 'RobotoSlab',
									color: "#7e3e1b",
									backgroundColor: "rgba(126, 62, 27, 0.2)",
								}}>
								Click here to see the results &nbsp;<ArrowDownwardOutlinedIcon style={{ transform: expandedProducts[product["Barcode"]] ? "rotate(180deg)" : "rotate(0deg)" }} />
							</IconButton></>
						)}
					</td>
				</> : <>
					<td id="categoryTt" className="data-row" style={{ width: '7vw', maxWidth: '8vw' }}>
						{product.category || "N/A"}
					</td>
					<td id="itemIdTt" className="data-row" style={{ width: '7vw', maxWidth: '8vw' }}>
						<a
							href={`https://walmart.com/ip/${product.item_id}`}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "underline", color: "#7e3e1b", fontSize: 16 }}
						>
							{product.item_id}
						</a>
						&nbsp;
						<ContentCopyIcon
							style={{ cursor: "copy", marginTop: "-.6%", fontSize: "75%" }}
							onClick={(e) => {
								navigator.clipboard.writeText(product.item_id);
							}}
						/>
					</td>
					<td id="priceTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
						<FormControl sx={{ m: 1 }} variant="standard">
							<InputLabel htmlFor={`${index}-price`} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Price</InputLabel>
							<Input
								id={`${index}-price`}
								size="small"
								style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}
								placeholder={`${product?.price}` || ""}
								startAdornment={<InputAdornment position="start" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>$</InputAdornment>}
								value={localPrice}
								onChange={(e) => {
									setLocalPrice(e.target.value)
									product.price = parseFloat(e.target.value)
								}}
								onBlur={(e) => {
									setPrice(product.item_id, e.target.value);
									props.setFilters(props.filters);
								}}
							/>
						</FormControl>
					</td>
					<td id="azPriceTt" className="data-row" style={{ width: "4.5vw", maxWidth: "4.5vw", }}>
						${product.walmart_price ? product.walmart_price.toFixed(2) : "N/A"}
						{product.used_bb &&
							<>
								<br />
								<span style={{ borderRadius: "30px", backgroundColor: "lightgreen", padding: "4% 10%", fontSize: "80%" }}>BuyBox</span>
							</>
						}
						<br />
					</td>
					<td id="azFeesTt" className="data-row">
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<div>Referral Fees: ${refFees.current.toFixed(2)}</div>
							{!hasPropertyIsWFS() ? <>
								<FormControl sx={{ m: 1, width: "50%" }} variant="standard">
									<InputLabel htmlFor={`${index}-shipping`} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Shipping Cost</InputLabel>
									<Input
										id={`${index}-shipping`}
										size="small"
										style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}
										placeholder="0"
										startAdornment={<InputAdornment position="start" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>$</InputAdornment>}
										value={localShippingCost}
										onChange={(e) => setLocalShippingCost(e.target.value)}
										onBlur={(e) => {
											setShippingCost(e.target.value || 0);
											product.shpng_cost = e.target.value || 0
											props.setFilters(props.filters);
										}}
									/>
								</FormControl>
							</> : <>
								<div>WFS Fees: {wfsCost === "N/A" ? wfsCost : `$${wfsCost.toFixed(2)}`}</div>
							</>}
						</div>
					</td>
					<td id="azOffersTt" className="data-row" style={{ width: '5vw', justifyContent: 'center' }}>
						<>
							{product.total_offers_count ?? 0}<sub><br />Sellers</sub>
						</>
					</td>
					<td id="costTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
						<FormControl sx={{ m: 1 }} variant="standard">
							<InputLabel htmlFor={`${index}-cost`} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Cost</InputLabel>
							<Input
								id={`${index}-cost`}
								type="number"
								size="small"
								style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}
								value={prep}
								inputProps={{ min: 0, step: 0.25 }}
								startAdornment={<InputAdornment position="start" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>$</InputAdornment>}
								onChange={(e) => { setPrep(e.target.value); product.prep = parseFloat(e.target.value); }}
								onBlur={(e) => {
									e.target.value === "" && setPrep(0)
									props.setFilters(props.filters);
								}}
							/>
						</FormControl>
					</td>
					<td id="profitTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
						{(() => {
							if (hasPropertyIsWFS() && wfsCost === "N/A") {
								product.profit = -1;
								return "N/A";
							}
							const profit = product.walmart_price - (localPrice ? parseFloat(localPrice) : product.price) - (hasPropertyIsWFS() ? wfsCost : parseFloat(localShippingCost ?? 0)) - refFees.current - parseFloat(prep);
							product.profit = profit;

							return (
								<p style={{ color: profit < 0 ? '#B33B3B' : '#769358' }}>
									{profit < 0 ? `-$${(profit * -1).toFixed(2)}` : `+$${profit.toFixed(2)}`}
								</p>
							);
						})()}
					</td>
					<td id="roiTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
						{(() => {
							if (hasPropertyIsWFS() && wfsCost === "N/A") {
								product.roi = -1;
								return "N/A";
							}

							const profit = product.walmart_price - (localPrice ? parseFloat(localPrice) : product.price) - (hasPropertyIsWFS() ? wfsCost : parseFloat(localShippingCost ?? 0)) - refFees.current - parseFloat(prep);
							const roi = (profit / (product.price + parseFloat(prep))) * 100;
							product.roi = roi;

							return (
								<p style={{ color: roi < 0 ? '#B33B3B' : '#769358' }}>
									{roi.toFixed(2)}%
								</p>
							);
						})()}
					</td>
					<td id="estSalesTt" className="data-row" style={{ width: "7vw", maxWidth: "6vw" }}>
						{product.reviews < 0 ? "N/A" : product.reviews}
					</td>
					<td id="estSalesTt" className="data-row" style={{ width: "7vw", maxWidth: "6vw" }}>
						<textarea style={{width: "80%", height: "10vh"}} onBlur={(e) => {localStorage.setItem(`note${product.item_id}`, e.target.value); e.target.placeholder = e.target.value; e.target.value = ""}} placeholder={localStorage.getItem(`note${product.item_id}`) ?? "Note"}/>
					</td>
					<td id="actionsTt" className="data-row">
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={!!anchorEl}
							onClose={handleClose}
							PaperProps={{
								style: {
									maxHeight: 48 * 4.5,
								},
							}}
						>
							<MenuItem onClick={() => { window.open(`https://www.google.com/search?q=${product.title.split(" | At")[0]}`, "_blank") }}>
								<Tooltip title="Search on Google">
									<Google />
								</Tooltip>
								<Typography>Search on Google</Typography>
							</MenuItem>
							<MenuItem onClick={() => {
								favorites.hasOwnProperty(product.item_id) && favorites[product.item_id] ? removeFromFavorites(product) : addToFavorites(product);
								handleClose();
							}}>
								<Tooltip title={favorites.hasOwnProperty(product.item_id) && favorites[product.item_id] ? "Remove from Favorites" : "Add to Favorites"}>
									{favorites.hasOwnProperty(product.item_id) && favorites[product.item_id] ? <FavoriteIcon /> : <FavoriteBorderIcon />}
								</Tooltip>
								<Typography>{favorites.hasOwnProperty(product.item_id) && favorites[product.item_id] ? "Remove from Favorites" : "Add to Favorites"}</Typography>
							</MenuItem>
							<MenuItem onClick={() => { window.open(`https://app.getmarter.com/?walmartId=${product.item_id}&price=${(localPrice ? parseFloat(localPrice) : product.price)}`, "_blank") }}>
								<Tooltip title="Search on Marter">
									<SearchSharpIcon />
								</Tooltip>
								<Typography>Search on Marter</Typography>
							</MenuItem>
							<MenuItem onClick={() => { window.open(`https://www.histomart.com/kp?item_id=${product.item_id}`, "_blank") }}>
								<Tooltip title="Search on Histomart">
									<SearchSharpIcon />
								</Tooltip>
								<Typography>Search on Histomart</Typography>
							</MenuItem>
						</Menu>
					</td>
				</>}
			</tr>
		</>
	}

	return (
		<>
			<div className="products-table-container" id="anisellerProductTable">
				<table className="products-table" style={{ width: '94vw', }}>
					<thead>
						<tr>
							<th>Product</th>
							<th>Category</th>
							<th>Item Id</th>
							<SortableTableHeader
								columnName={<>Price</>}
								sortKey="price"
								filters={props.filters}
								setSortASC={props.setSortASC}
								setFilters={props.setFilters}
							/>
							<th>
								<CustomTooltip
									arrow
									title={<>
										<Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Buy Box price with shipping (if available).</li><li>The lowest new price without shipping.</li></ul></Typography>
									</>}
								>
									Price on<br />Walmart<sub style={{ fontSize: 12, }}>(i)</sub>
								</CustomTooltip>
							</th>
							<th>
								<CustomTooltip
									arrow
									title={<>
										<Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Walmart Referral Fee + Fulfillment Cost (WFS)</li></ul></Typography>
									</>}
								>
									Walmart<br />Fees<sub style={{ fontSize: 12, }}>(i)</sub>
								</CustomTooltip>
							</th>
							<th>Amount of sellers</th>
							<th>
								<CustomTooltip
									arrow
									title={<>
										<Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Input any additional costs, such as prep cost, WFS, and others.</li></ul></Typography>
									</>}
								>
									Costs<sub style={{ fontSize: 12, }}>(i)</sub>
								</CustomTooltip>
							</th>
							<SortableTableHeader
								columnName={<>Profit</>}
								sortKey="profit"
								filters={props.filters}
								setSortASC={props.setSortASC}
								setFilters={props.setFilters}
							/>
							<SortableTableHeader
								columnName={<>ROI</>}
								sortKey="roi"
								filters={props.filters}
								setSortASC={props.setSortASC}
								setFilters={props.setFilters}
							/>
							<th>Review<br />Count</th>
							<th>Notes</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{(props.data.length === 0 || props.data.slice((page - 1) * 10, page * 10).length === 0) &&
							props.isProductsLoading === false && (
								<td style={{ textAlign: "center" }} colSpan={14}>No products.{props.data.length === 0 && " No scan credit used."}</td>
							)}

						{props.data.slice((page - 1) * 10, page * 10).map((product, index) => {
							return (
								<React.Fragment key={product["Barcode"]}>
									<ProductRowDisplay
										product={product}
										index={index}
										prep={prep}
										setPrep={handleChangePrepCost}
										price={pricesList[product.item_id]}
										setPrice={handlePriceChange}
										shippingCost={shippingCost}
										setShippingCost={handleChangeShippingCost}
										setData={props.setData}
									/>
									{expandedProducts[product["Barcode"]] && product.results &&
										product.results.slice(0, 10).map((p, pIndex) => {
											const { results, ...rest } = product;
											const fullProduct = { ...rest, ...p };

											return (
												<ProductRowDisplay
													key={fullProduct.item_id}
													product={fullProduct}
													index={pIndex}
													isResult={true}
													prep={prep}
													setPrep={handleChangePrepCost}
													price={pricesList[fullProduct.item_id]}
													setPrice={handlePriceChange}
													shippingCost={shippingCost}
													setShippingCost={handleChangeShippingCost}
													setData={props.setData}
												/>
											)
										})
									}
									{expandedProducts[product["Barcode"]] && product?.results && product?.results.length > 10 && (
										<tr>
											<td colSpan="100%">
												<Button
													style={{
														width: "100%",
														height: "60px",
														fontWeight: "bold",
														cursor: "pointer",
														border: '2px solid rgba(126, 62, 27, 0.5)',
														color: "#7e3e1b",
														backgroundColor: "rgba(126, 62, 27, 0.2)",
													}}
													onClick={() => {
														const products = product.results.slice(10, product.results.length).map(p => {
															const { results, ...rest } = product;
															const fullProduct = { ...rest, ...p };
															return fullProduct;
														});

														setSubTableData({
															color: barcodeColorMap[product['Barcode']],
															products: products
														});
														setOpenSubTableModal(true);
													}}
												>
													Show {product.results.length - 10} More Results
												</Button>
											</td>
										</tr>
									)}
								</React.Fragment>
							)
						})}
						{(page >= props.data.length / 10) && props.isProductsLoading && <tr><td colSpan="100%" style={{
							width: "100%",
							fontWeight: "bold",
							border: '2px solid rgba(126, 62, 27, 0.5)',
							color: "#7e3e1b",
							backgroundColor: "rgba(126, 62, 27, 0.2)",
						}}>&emsp;Scanning...
						<LinearProgress color="#7e3e1b" style={{margin: "0.5%", padding: "0.1%"}} /></td></tr>}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={"14"} style={{ border: "none" }}>
								<div style={{ display: 'flex', justifyContent: 'space-evenly', margin: 15, }}>
									<Button
										variant={page === 1 ? "outlined" : "contained"}
										onClick={() => { setPage(p => p - 1); window.location.href = '#'; }}
										disabled={page === 1}
										style={{
											textTransform: 'none',
											fontFamily: 'RobotoSlab',
											color: page === 1 ? '#9e9e9e' : '#FCF8F3',
											borderRadius: '20px',
											backgroundColor: page === 1 ? '#FCF8F3' : '#7e3e1b',
											border: `1px solid ${page === 1 ? '#9e9e9e' : '#FCF8F3'}`,
										}}
									>
										Previous Page
									</Button>
									<span>Page {page}</span>
									<Button
										variant={((page) * 10) > props.data.length ? "outlined" : "contained"}
										onClick={() => { setPage(p => p + 1); window.location.href = '#'; }}
										disabled={((page) * 10) > props.data.length}
										style={{
											textTransform: 'none',
											fontFamily: 'RobotoSlab',
											color: ((page) * 10) > props.data.length ? '#9e9e9e' : '#FCF8F3',
											borderRadius: '20px',
											backgroundColor: ((page) * 10) > props.data.length ? '#FCF8F3' : '#7e3e1b',
											border: `1px solid ${((page) * 10) > props.data.length ? '#9e9e9e' : '#FCF8F3'}`,
										}}
									>
										Next Page
									</Button>
								</div>
							</td>
						</tr>
					</tfoot>
				</table >
			</div >

			<Modal open={openSearchModal} onClose={() => setOpenSearchModal(false)}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '26vw',
						bgcolor: '#FCF8F3',
						borderRadius: 2,
						border: '4px solid rgba(126, 62, 27, 0.5)',
						boxShadow: 24,
						p: 4,
						outline: 'none',
					}}
				>
					<Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
						Check in other suppliers
					</Typography>

					<p style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
						A specific Item Id can be found from multiple suppliers in Nepeto. By clicking the Nepeto button, you can compare all prices from different suppliers.
					</p>

					{/* Suppliers buttons */}
					<Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
						<img
							className="graphicon"
							alt="ebay"
							src={"/assets/ebay.png"}
							style={{ width: "5vw", marginTop: "10px", height: "3vw", borderRadius: "20px", border: "2px solid gray", padding: "0.7vw", objectFit: "contain", cursor: "pointer", }}
							onClick={() =>
								window.open(searchModal.upc !== undefined ?
									`https://www.ebay.com/sch/i.html?&_nkw=${searchModal.upc}&_odkw=${searchModal.upc}` :
									`https://www.ebay.com/sch/i.html?&_nkw=${searchModal.title.split(" | At")[0].slice(0, 40)}&_odkw=${searchModal.title.split(" | At")[0].slice(0, 40)}`,
									"_blank"
								)
							}
						/>

						<img
							className="graphicon"
							alt="nepeto"
							src={"/assets/nepeto.png"}
							style={{ width: "5vw", marginTop: "10px", height: "3vw", borderRadius: "20px", border: "2px solid gray", padding: "0.7vw", objectFit: "contain", cursor: "pointer", }}
							onClick={() =>
								window.open(`https://nepeto.com/retail?asin=${searchModal.asin.split("/")[searchModal.asin.split("/").length - 1]}`, "_blank")
							}
						/>

						<img
							className="graphicon"
							alt="google"
							src={"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/250px-Google_2015_logo.svg.png"}
							style={{ width: "5vw", marginTop: "10px", height: "3vw", borderRadius: "20px", border: "2px solid gray", padding: "0.7vw", objectFit: "contain", cursor: "pointer", }}
							onClick={() =>
								window.open(`https://www.google.com/search?q=${searchModal.title.split(" | At")[0]}`, "_blank")
							}
						/>
					</Box>

					{/* Cancel button */}
					<Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
						<Button variant="contained" onClick={() => setOpenSearchModal(false)} style={{
							textTransform: 'none',
							fontFamily: 'RobotoSlab',
							color: '#FCF8F3',
							borderRadius: '20px',
							backgroundColor: '#B33B3B',
							border: '1px solid #FCF8F3'
						}} >
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
			<SubTableModal open={openSubTableModal} setOpen={setOpenSubTableModal} data={subTableData?.products || []} color={subTableData?.color || ""} identifier={props.identifier} />
		</>
	);
}

export default WalmartProductsTable;