import { getUser } from "./authService";

export default async function addScanedFile(fileName, lineCount, identifier, store) {
  const curr_scan = { fileName: fileName, lineCount: lineCount, identifier: identifier, store, date: new Date().toLocaleString("en-US", { hour12: false }) };
  const email = getUser();

  const obj = { email: email, curr_scan: curr_scan };

  await fetch("https://server.nepeto.com/aniseller_update_scans/", {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj)
  })
}

export async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export async function addToFavoritesDB(product) {
  const favProduct = { ...product, date: new Date().toLocaleDateString("en-US", { hour12: false }) };
  const email = getUser();

  const obj = { email: email, favProduct: favProduct };

  await fetch("https://server.nepeto.com/aniseller_add_to_favorites/", {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj)
  })
}

export async function removeFromFavoritesDB(asin) {
  const email = getUser();

  await fetch(`https://server.nepeto.com/aniseller_remove_from_favorites/${email}/${asin}/`);
}

export function calcRefFees(category, price, subCat) {
  if (!category || !subCat) return price * 0.15;

  if (category === "Clothing") {
    if (price <= 15) {
      return price * 0.05
    }
    if (price <= 20) {
      return price * 0.1
    }
  }
  if (subCat === "Auto Electronics" || category === "Cell Phones" || subCat.includes("Cameras")) {
    return price * 0.08
  }
  if (category === "Auto & Tires" || category === "Musical Instruments") {
    if (subCat.includes("Tires")) {
      return price * 0.1
    }
    return price * 0.12
  }
  if (category === "Baby" || category === "Beauty" || category === "Personal Care" || category.includes("Health")) {
    if (price <= 10) {
      return price * 0.08
    }
  }
  if (category === "Food" || category === "Grocery") {
    if (price <= 15) {
      return price * 0.08
    }
  }
  if (category === "Electronics" && price > 100) {
    return price * 0.08
  }
  if (category === "Jewelry") {
    return price * 0.2
  }
  if (subCat.includes("Computers")) {
    return price * 0.06
  }

  return price * 0.15
}

export const calculateWfs = (name, height, length, width, weight, userSalePrice, category) => {
  if (!weight || !category || !height || !length || !width) {
    return "N/A";
  }

  const isAntimicrobial = name.toLowerCase().includes('antimicrobial');
  if (isAntimicrobial) return "N/A";

  let wfs = 0;
  let tempWeight = -1

  tempWeight = parseFloat(weight.toString().match(/-?\d+(\.\d+)?/g)[0]);

  if (weight.toString().toLowerCase().includes("o")) {
    tempWeight *= 0.0625
  }
  tempWeight = tempWeight.toFixed(3);

  wfs = calculateShippingWeight(height, length, width, tempWeight);

  if (wfs !== 0) {
    if ((userSalePrice ?? 10) < 10) {
      wfs += 1;
    }

    if (category === "Clothing") {
      wfs += 0.5;
    }
  }
  return wfs;
}

function calculateShippingWeight(height, length, width, weight) {
  let dim_weight = (height * length * width) / 139;

  if (0 < weight && weight < 0.75 && weight !== -1) {
    return 3.45;
  }
  if (0.75 <= weight && weight < 1 && weight !== -1) {
    return 4.95;
  }

  const shipping_weight = Math.ceil(Math.max(weight, dim_weight) + 0.25);

  if (shipping_weight <= 1) {
    return 3.45;
  } else if (shipping_weight === 2) {
    return 4.95;
  } else if (shipping_weight === 3) {
    return 5.45;
  } else if (shipping_weight >= 4 && shipping_weight <= 20) {
    const additional_weight = shipping_weight - 4;
    return 5.75 + (0.4 * additional_weight);
  } else if (shipping_weight >= 21 && shipping_weight <= 30) {
    const additional_weight = shipping_weight - 21;
    return 15.55 + (0.4 * additional_weight);
  } else if (shipping_weight >= 31 && shipping_weight <= 50) {
    const additional_weight = shipping_weight - 31;
    return 14.55 + (0.4 * additional_weight);
  } else if (shipping_weight >= 51) {
    const additional_weight = shipping_weight - 51;
    return 17.55 + (0.4 * additional_weight);
  }
}
