import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar';
import CSVScanner from './pages/Scanner/CSVScanner';
import HomePage from './pages/Home/HomePage';
import LoginSignUp from './pages/Login/Login';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import { isUserAuthenticated } from './utils/authService';
import Pricing from './pages/Pricing/Pricing';
import Account from './pages/Account/Account';

function App() {
  const [sellerId, setSellerId] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [hideCredits, setHideCredits] = useState(0);
  const [activePlanLevel, setActivePlanLevel] = useState("Master Plan");
  // const [user, setUser] = useState("");
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    setUserAuthenticated(isUserAuthenticated());
  }, []);

  return (
    <BrowserRouter>
      <Navbar isAuth={userAuthenticated} setIsAuth={setUserAuthenticated} setIsFlipped={setIsSignIn} />
      <Routes>
        <Route path="/">
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<LoginSignUp isAuth={userAuthenticated} setIsAuth={setUserAuthenticated} isFlipped={isSignIn} setIsFlipped={setIsSignIn} isYearly={isYearly} />} />
          <Route path="dashboard" element={<Dashboard isAuth={userAuthenticated} />} />
          <Route path="pricing" element={<Pricing isAuth={userAuthenticated} setIsFlipped={setIsSignIn} isYearly={isYearly} setIsYearly={setIsYearly} />} />
          <Route path="account" element={<Account />} />
          <Route path="csvscan" element={<CSVScanner sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} hideCredits={hideCredits} activePlanLevel={activePlanLevel} isAuth={userAuthenticated} />} />
          <Route
            path="*"
            element={
              <div style={{
                textAlign: 'center',
                padding: '80px',
                backgroundColor: '#f8f9fa',
                borderRadius: '10px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '600px',
                margin: 'auto',
                fontFamily: 'Arial, sans-serif',
                color: '#495057',
                marginTop: "10%"
              }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#dc3545' }}>Page Not Found</h2>
                <p style={{ fontSize: '16px' }}>Oops! It looks like the page you're looking for doesn't exist. Please contact us via email for assistance.<br /><br />help@aniseller.com</p>
                <a href="mailto:help@aniseller.com" style={{
                  textDecoration: 'none',
                  color: '#fff',
                  backgroundColor: '#007bff',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  fontSize: '16px',
                  marginTop: '20px',
                  display: 'inline-block'
                }}>Contact Us</a>
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
