import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from '@mui/material/styles';
import ProductsTable from '../../components/amazon/ProductsTable';
import FilterBar from '../../components/amazon/FilterBar';
import WalmartFilterBar from "../../components/walmart/WalmartFilterBar";
import WalmartProductsTable from "../../components/walmart/WalmartProductsTable";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";
import addScanedFile from "../../utils/helpers";
import { debounce } from 'lodash';
import { getUser } from "../../utils/authService";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const stores = {
  "amazon": {
    title: "Amazon",
    scanOptions: {
      "ASIN": "ASIN",
      "UPC/EAN": "Barcode"
    }
  },
  "walmart": {
    title: "Walmart",
    scanOptions: {
      "Item ID": "Item ID",
      "UPC/EAN": "Barcode"
    }
  }
}

function CSVScanner(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);
  const [alertModalMsg, setAlertModalMsg] = useState("");
  const [navigatePage, setNavigatePage] = useState("");
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [invalidColumns, setInvalidColumns] = useState({ identifier: false, price: false });

  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [vlookupObj, setVlookupObj] = useState({});
  const [vlookupLoading, setVlookupLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [sortASC, setSortASC] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const [openColumnMappingModal, setOpenColumnMappingModal] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);

  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  function calculateROI(item, settings) {
    let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
    let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
    let azPrice = item.az_price;
    let fbaCost = settings.isFBM ? 0 : item.fba_fee;
    let sellingOnAzFees = item.ref_fee_perc * item.az_price;

    return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
  }

  function filterData(data, filters) {
    return data.filter(item => {

      // noVariations
      if (parseInt(filters.noVariations || "0") !== 0 && item.variations !== "null") {
        return false;
      }

      // amazonNotSeller
      if (parseInt(filters.amazonNotSeller || "0") !== 0) {
        if (item.az_sells_it) {
          return false;
        }
      }

      // top_bsr
      if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
        let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
        let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

        if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
          return false;
        }

        if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
          return false;
        }
      }

      // profit
      if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
        let profitValue;
        if (selectedStore === "amazon") {
          const costPrep = parseFloat(localStorage.getItem("costPrep") || 0);
          const price = item.price * (1 - ((filters.priceCoupon || 0) / 100));
          const azPrice = item.az_price;
          const fbaCost = filters.isFBM ? 0 : item.fba_fee;
          const sellingOnAzFees = item.ref_fee_perc * item.az_price;
          profitValue = azPrice - price - fbaCost - costPrep - sellingOnAzFees;
        } else {
          profitValue = item?.asin === undefined ? (item?.profit < 0 ? 0 : item?.profit) : profitValue;
        }

        if (profitValue === undefined) return false;
        if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
          return false;
        }

        if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
          return false;
        }
      }

      // roi
      if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
        if (item?.asin === undefined) {
          // Walmart
          const roi = parseFloat(item?.roi) < 0 ? 0 : parseFloat(item?.roi);
          if (filters.roi[0] !== -1 && roi < filters.roi[0]) {
            return false;
          }
          if (filters.roi[1] !== -1 && roi > filters.roi[1]) {
            return false;
          }
          if (item.roi === undefined || isNaN(item.roi)) return false;


        } else {
          // Amazon
          const roi = calculateROI(item, filters);
          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }
          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }
      }

      // offers
      if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
        let offersCount = parseInt(item.total_offers_count) || 0;

        if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
          return false;
        }
        if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
          return false;
        }
      }

      // reviews
      if (filters.reviews && (filters.reviews[0] !== -1 || filters.reviews[1] !== -1)) {
        let reviews = parseInt(item.reviews) || 0;
        if (reviews === undefined) return false;
        if (filters.reviews[0] !== -1 && reviews < filters.reviews[0]) {
          return false;
        }
        if (filters.reviews[1] !== -1 && reviews > filters.reviews[1]) {
          return false;
        }
      }

      // vlookup
      if (filters.vlookup && filters.vlookup !== "") {
        let vlookup = filters.vlookup;
        let idString = '';
        const priceDict = {};
        if (vlookup[0].hasOwnProperty("ASIN")) {
          vlookup.forEach(product => {
            idString += product.ASIN + ',';
            priceDict[product.ASIN] = { "price": product.Price, "source": product?.Source ?? "" };
          });
          if (!idString.includes(item.asin)) {
            return false
          }
        } else if (vlookup[0].hasOwnProperty("Barcode")) {
          vlookup.forEach(product => {
            idString += product.Barcode.replaceAll('.', '').replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "") + ',';
            priceDict[product.Barcode.replaceAll('.', '').replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")] = { "price": product.Price, "source": product?.Source ?? "" };
          });
          if (!item.upcs.split(',').some(upc => idString.includes(upc.replaceAll('.', '').replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")))) {
            return false
          }
        }
        setVlookupObj(priceDict);
      }

      // search
      if (filters.search && filters.search !== "") {
        let searchLower = filters.search.toLowerCase();
        if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search)) {
          return false;
        }
      }

      // category
      if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
        return false;
      }

      // qty, moq, bsr
      for (let filterAttr of ["qty", "moq", "bsr"]) {
        if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
          let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

          if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
            return false;
          }

          if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
            return false;
          }
        }
      }

      // price
      if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
        let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

        if (filters.price[0] !== -1 && price < filters.price[0]) {
          return false;
        }

        if (filters.price[1] !== -1 && price > filters.price[1]) {
          return false;
        }
      }

      // sales
      if (filters.sales && (filters.sales[0] !== -1 || filters.sales[1] !== -1)) {
        let sales = item.keepa_monthly_sold > 0 ? item.keepa_monthly_sold : item.estimate_sales_30_days;

        if (filters.sales[0] !== -1 && sales < filters.sales[0]) {
          return false;
        }

        if (filters.sales[1] !== -1 && sales > filters.sales[1]) {
          return false;
        }
      }

      // bsr drops 30 days
      if (filters.bsr_drops_30_days && (filters.bsr_drops_30_days[0] !== -1 || filters.bsr_drops_30_days[1] !== -1)) {
        let drops = item.sales_rank_drops_30_days;

        if (filters.bsr_drops_30_days[0] !== -1 && drops < filters.bsr_drops_30_days[0]) {
          return false;
        }

        if (filters.bsr_drops_30_days[1] !== -1 && drops > filters.bsr_drops_30_days[1]) {
          return false;
        }
      }

      return true;
    });
  }

  const sortFunction = (a, b) => {
    if (filters.sortBy) {
      let getKey = (n, key) => {
        if (filters.sortBy[0] === "roi") {
          if (selectedStore === "amazon") {
            return parseFloat(calculateROI(n, filters))
          } else {
            return n.roi
          }
        }
        if (filters.sortBy[0] === "profit") {
          if (selectedStore === "amazon") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_fee) - (n.ref_fee_perc * n.az_price) - parseFloat(localStorage.getItem("costPrep") || 0))
          } else {
            return n.profit
          }
        }
        return parseFloat(n[key])
      }
      if (filters.sortBy[1]) {
        return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
      } else {
        return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
      }
    } else {
      return 1
    }
  }

  useEffect(() => {
    setFilteredData(filterData(data, filters).sort(sortFunction));
  }, [filters, data]);

  const capitalizeKeys = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (key.toLocaleLowerCase().includes("barcode") || key.toLocaleLowerCase().includes("upc") || key.toLocaleLowerCase().includes("ean")) {
        acc["Barcode"] = obj[key];
        return acc;
      }
      if (key.toLocaleLowerCase().includes("asin")) {
        acc["ASIN"] = obj[key];
        return acc;
      }
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      acc[capitalizedKey] = obj[key];
      return acc;
    }, {});
  };

  const sendFile = async (data_l) => {
    // Process data_l in batches with delay
    const batchSize = 10;
    let index = 0;

    async function processBatch() {
      if (index < data_l.length && window.location.href.includes("csvscan")) {
        setIsProductsLoading(true);
        const batch = data_l.slice(index, index + batchSize).map(item => {
          const findKey = (target) =>
            Object.keys(item).find(key => key.trim().toLowerCase() === target.toLowerCase());

          const identifierKey = findKey(identifier);
          const priceKey = findKey("price");
          const nameKey = findKey("name");
          const imageKey = findKey("image");

          return {
            [identifier]: item[[identifierKey]].includes("E+") ? Number(item[[identifierKey]]).toFixed(0).toString() : item[[identifierKey]],
            "Price": item[[priceKey]] ? item[[priceKey]].replace("$", "").replaceAll(" ", "") : -1,
            "Name": item[[nameKey]],
            "Image": item[[imageKey]]
          }
        });

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            Accept: "application/json; charset=utf8",
            "Content-Type": "application/json; charset=utf8",
          },
          body: JSON.stringify({
            csv_file: batch,
            identifier: identifier,
            email: getUser()
          }),
        };

        try {
          switch (selectedStore) {
            case "walmart":
              await fetch("https://server.getmarter.com/upload_scanner_csv_aniseller_walmart/", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                  if (data === "No permissions") {
                    navigate("/pricing")
                  } else {
                    setData((prevData) => {
                      const sortedData = [...prevData, ...data.sort((a, b) => {
                        if (a.title === "" && b.title !== "") return 1; // Move empty title to the end
                        if (a.title !== "" && b.title === "") return -1; // Keep non-empty title at the front
                        return 0; // Keep order for titles that are both empty or both non-empty
                      })]

                      return sortedData;
                    });
                    if (index >= data_l.length) {
                      setIsProductsLoading(false);
                    }
                  }
                })
                .catch((e) => {
                  setModalMsg("Error! Try again or contact us please.");
                  setOpenModal(true);
                  setLoading(false);
                });
              break;
            case "amazon":
              await fetch(" https://server.nepeto.com/upload_scanner_csv_aniseller/", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                  if (data === "No permissions") {
                    navigate("/pricing")
                  } else {
                    if (data.length > 0 && !props.isMobile) {
                      let filteredData = data
                      if (filteredData.length > 0) {
                        let filteredDataWithHide = filteredData.filter(prod => (localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU") === null || (new Date() > localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU"))))
                        if (filteredDataWithHide.length > 0) {
                          setData((prevData) => [...prevData, ...filteredDataWithHide]);
                          if (index >= data_l.length) {
                            setIsProductsLoading(false);
                          }
                        }
                      }
                    } else {
                      setData((prevData) => [...prevData, ...data])
                      if (index >= data_l.length) {
                        setIsProductsLoading(false);
                      }
                    }
                  }
                })
                .catch((e) => {
                  setModalMsg("Error! Try again or contact us please.");
                  setOpenModal(true);
                  setLoading(false);
                });
              break;
            default:
              break;
          }
        } catch (e) {
          setModalMsg("Error! Try again or contact us please.");
          setOpenModal(true);
          setLoading(false);
        }

        index += batchSize;

        // Set timeout for 30 second before processing next batch
        setTimeout(processBatch, 30000);
      } else {
        setIsProductsLoading(false);
      }
    }

    // Start processing batches
    processBatch();
  }

  function uploadCSV(file) {
    if (!selectedStore) return;

    const lastClickTimestamp = localStorage.getItem('lastClickTimestamp') || -1;
    const minutesSinceLastClick = (Date.now() - parseInt(lastClickTimestamp)) / (1000 * 60);
    if (minutesSinceLastClick < 2 && lastClickTimestamp !== -1) {
      setLoading(false);
      setRefresh(true);
      setModalMsg(`Please wait ${((2 - minutesSinceLastClick) * 60).toFixed(0)} seconds before scanning again!`);
      setOpenModal(true);
      return
    }
    if (file.size > 10000000) {
      setLoading(false);
      setRefresh(true);
      setModalMsg("Your file is larger than 10MB. Please consider splitting the Excel file and sending multiple scan requests.");
      setOpenModal(true);
    } else {
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const fileName = file.name.toLowerCase();
          if (fileName.endsWith('.csv')) {
            const text = e.target.result;
            Papa.parse(text, {
              header: true,
              complete: (results) => {
                const headers = Object.keys(results.data[0] || {});
                const editData = results.data.map((item) => capitalizeKeys(item));
                if (editData.some(item => 'Barcode' in item || (selectedStore === "amazon" ? 'ASIN' in item : 'Item ID' in item))) {
                  localStorage.setItem('lastClickTimestamp', Date.now());
                  addScanedFile(fileName, editData.length, identifier, selectedStore);
                  sendFile(editData);
                } else {
                  setCsvHeaders(headers);
                  setParsedData(editData);
                  setOpenColumnMappingModal(true);
                }
              },
            });
          } else if (fileName.endsWith('.xlsx')) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const headers = json[0];
            const dataRows = json.slice(1).map((row) => {
              const obj = {};
              headers.forEach((header, index) => {
                obj[header] = row[index];
              });
              return obj;
            });
            const editData = dataRows.map((item) => capitalizeKeys(item));
            if (editData.some(item => 'Barcode' in item || (selectedStore === "amazon" ? 'ASIN' in item : 'Item ID' in item))) {
              localStorage.setItem('lastClickTimestamp', Date.now());
              addScanedFile(fileName, editData.length, identifier, selectedStore);
              sendFile(editData);
            } else {
              setCsvHeaders(headers);
              setParsedData(editData);
              setOpenColumnMappingModal(true);
            }
          }
        };

        if (file.name.toLowerCase().endsWith('.csv')) {
          reader.readAsText(file);
        } else if (file.name.toLowerCase().endsWith('.xlsx')) {
          reader.readAsArrayBuffer(file);
        }
      }
    }
  }

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      setLoading(true);
      uploadCSV(files[0]);
      setCurrentFile(files[0]);
    } else {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setIdentifier(event.target.value);
    if (!props.isAuth) {
      setNavigatePage('/login');
      setAlertModalMsg("Please log in to your account, or create one if you haven't already.");
      setOpenAlertModal(true);
    }
    if (!subscribed) {
      setNavigatePage('/pricing');
      setAlertModalMsg("To access this, you need to be subscribed.");
      setOpenAlertModal(true);
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      fetch(
        `https://server.nepeto.com/aniseller_sub_check/${getUser()}/`,
        {
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSubscribed(data);
        })
        .catch(() => {
        });
    };
    checkAccess();
  }, []);

  const handleColumnMappingConfirm = (mapping) => {
    const identifierKey = Object.keys(parsedData[0] || {}).find(key => key.toLowerCase() === mapping.identifier.toLowerCase());
    const priceKey = Object.keys(parsedData[0] || {}).find(key => key.toLowerCase() === mapping.price.toLowerCase());
    
    const hasValidIdentifier = parsedData.some(item => {
      const value = item[identifierKey]?.toString() || '';
      const cleanValue = value.replace(/^['"]|['"]$/g, '').replace(/[-\s]/g, '');
      
      switch(identifier) {
        case 'ASIN':
          const asinMatch = cleanValue.match(/(?:[/dp/]|^)([A-Z0-9]{10})(?:[/]|$)/);
          return asinMatch && asinMatch[1]?.length === 10;
        case 'Barcode':
          return cleanValue.length >= 8 && /^\d+$/.test(cleanValue);
        case 'Item ID':
          return cleanValue.length >= 5 && /^\d+$/.test(cleanValue);
        default:
          return false;
      }
    });
    const hasValidPrice = parsedData.some(item => {
      const value = item[priceKey]?.toString() || '';
      const cleanValue = value.replace(/^\$/, '').trim();
      return cleanValue && cleanValue !== "-1" && !isNaN(parseFloat(cleanValue));
    });
    if (!hasValidIdentifier || !hasValidPrice) {
      setInvalidColumns({
        identifier: !hasValidIdentifier,
        price: !hasValidPrice
      });
      return;
    }
    const mappedData = parsedData.map(item => {
      const nameKey = mapping.name ? Object.keys(item).find(key => key.toLowerCase() === mapping.name.toLowerCase()) : null;
      const imageKey = mapping.image ? Object.keys(item).find(key => key.toLowerCase() === mapping.image.toLowerCase()) : null;
      
      return {
        [identifier]: item[identifierKey],
        Price: item[priceKey]?.toString().trim() || "-1",
        Name: nameKey ? item[nameKey] : "",
        Image: imageKey ? item[imageKey] : ""
      };
    });
    const validData = mappedData.filter(item => {
      const identifierValue = item[identifier]?.toString() || '';
      const priceValue = item.Price;
      let cleanIdentifier;
      switch(identifier) {
        case 'ASIN':
          const asinMatch = identifierValue.match(/(?:[/dp/]|$)([A-Z0-9]{10})/);
          cleanIdentifier = asinMatch ? asinMatch[1] : identifierValue.replace(/^['"]|['"]$/g, '').replace(/[-\s]/g, '').toUpperCase();
          break;
        case 'Barcode':
          cleanIdentifier = identifierValue.replace(/^['"]|['"]$/g, '').replace(/[-\s]/g, '');
          break;
        case 'Item ID':
          cleanIdentifier = identifierValue.replace(/^['"]|['"]$/g, '').replace(/[-\s]/g, '');
          break;
        default:
          cleanIdentifier = identifierValue;
      }
      let isValidIdentifier;
      switch(identifier) {
        case 'ASIN':
          isValidIdentifier = /^[A-Z0-9]{10}$/.test(cleanIdentifier);
          break;
        case 'Barcode':
          isValidIdentifier = cleanIdentifier.length >= 8 && /^\d+$/.test(cleanIdentifier);
          break;
        case 'Item ID':
          isValidIdentifier = cleanIdentifier.length >= 5 && /^\d+$/.test(cleanIdentifier);
          break;
        default:
          isValidIdentifier = false;
      }
      const cleanPrice = priceValue.replace(/^\$/, '').trim();
      const isValidPrice = cleanPrice && cleanPrice !== "-1" && !isNaN(parseFloat(cleanPrice));
      if (isValidIdentifier) {
        item[identifier] = cleanIdentifier;
      }
      if (isValidPrice) {
        item.Price = cleanPrice;
      }
      return isValidIdentifier && isValidPrice;
    });
    if (validData.length === 0) {
      setOpenColumnMappingModal(false);
      setModalMsg("No valid data found! Please re-check your file and ensure the identifiers and prices are in the correct format.");
      setOpenModal(true);
      
      setTimeout(() => {
        setOpenColumnMappingModal(true);
      }, 500);
      return;
    }
    localStorage.setItem('lastClickTimestamp', Date.now());
    addScanedFile(currentFile.name, validData.length, identifier, selectedStore);
    sendFile(validData);
    setOpenColumnMappingModal(false);
  };

  if (!selectedStore) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "30px" }}>
        <h1 style={{ fontSize: "2rem", fontWeight: "bold" }}>Choose Marketplace</h1>

        <div style={{ display: "flex", gap: "40px" }}>
          {Object.entries(stores).map(([key, store]) => (
            <div
              key={key}
              onClick={() => setSelectedStore(key)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "220px",
                height: "120px",
                borderRadius: "20px",
                backgroundColor: key === "amazon" ? "#ff9900" : "#0071dc",
                color: "white",
                fontSize: "1.2rem",
                fontWeight: "bold",
                cursor: "pointer",
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
                transition: "all 0.3s ease",
                userSelect: "none",
                border: "none",
                textTransform: "uppercase",
              }}
            >
              {store.title}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {data.length === 0 ? (
        <>
          <div style={{ marginBottom: "3.5vh" }}>
            <h1
              style={{
                fontSize: props.isMobile ? "3vh" : "2vw",
                fontWeight: "380",
                textAlign: "center",
                color: "#7e3e1b",
                marginBottom: props.isMobile && "2vh",
              }}
            >
              CSV Scanner
            </h1>
            <h2
              style={{
                fontSize: props.isMobile ? "1.5vh" : "1vw",
                fontWeight: "380",
                textAlign: "center",
                color: "rgb(230,50,50)",
                marginBottom: props.isMobile && "2vh",
              }}
            >
              The file should include the following: Identifier Code ({Object.keys(stores[selectedStore].scanOptions).join("/")}), Price, Name (optional), Image (optional).
              <br /><strong>Download and use our template to ensure it works as expected!</strong>
            </h2>
          </div>
          {identifier === "" ? (
            <>
              <div style={{ display: "flex", flexDirection: "row", width: "50vw", margin: "auto", justifyContent: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "2vh 1vw 7vh 1vw" }}>
                <h2 style={{ fontSize: "150%", marginBottom: "2vh" }}>Select the identifier for product matching</h2>

                <Box sx={{ marginLeft: 10, minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Identifier</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={identifier}
                      label="Identifier"
                      onChange={handleChange}
                    >
                      {Object.entries(stores[selectedStore].scanOptions).map(([key, value]) => (
                        <MenuItem key={key} value={value} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>{key}</MenuItem>
                      ))}
                      {/* <MenuItem value={"Barcode"} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>UPC / EAN</MenuItem> */}
                      {/* <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </>
          ) : (
            <div style={{ width: "50vw", margin: "auto", alignItems: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "2vh 1vw 2vh 1vw" }}>
              <h2 style={{ marginBottom: "2vh" }}>Upload the CSV file</h2>
              <h3 style={{ marginBottom: "2vh" }}>Make sure you use our template or use the same column names!</h3>
              <span onClick={() => window.location.href = `data:text/csv;charset=utf-8,${identifier},Price,Name,Image\n`} style={{ color: "slateblue", textDecoration: "underline", cursor: "pointer", fontSize: "150%", fontWeight: "600" }}>Click here to download the template</span>

              <Box sx={{ border: '2px dashed #ccc', padding: '16px', textAlign: 'center', width: '30%', borderRadius: '8px', cursor: 'pointer', margin: '25px auto', }}>
                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} size="large" style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#FCF8F3',
                  borderRadius: '10px',
                  backgroundColor: '#7e3e1b',
                  border: '1px solid #FCF8F3',
                }}>
                  Choose a file
                  <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".csv,.xlsx" />
                </Button>
                <Typography variant="body2" sx={{ marginTop: '8px', fontFamily: 'RobotoSlab', }}>
                  (Accept .csv and .xlsx files)
                </Typography>

                {loading ?
                  <div style={{ color: '#7e3e1b', }}>
                    <LinearProgress sx={{ marginTop: '16px' }} color={'inherit'} />
                  </div>
                  :
                  <Typography variant="caption" sx={{ display: 'block', marginTop: '8px', fontFamily: 'RobotoSlab', }}>
                    No file uploaded
                  </Typography>
                }
              </Box>

            </div>
          )}
        </>
      ) : (
        <>
          {selectedStore === "amazon" && <>
            <FilterBar
              data={data}
              setFilters={setFilters}
              filters={filters}
              activePlanLevel={props.activePlanLevel}
              vlookupLoading={vlookupLoading}
              setVlookupLoading={setVlookupLoading}
            />
            <ProductsTable
              data={filteredData}
              setData={setData}
              isMobile={props.isMobile}
              supplierType={"storefront"}
              hideCredits={props.hideCredits}
              showAdv={true}
              isProductsLoading={isProductsLoading}
              filters={filters}
              setFilters={setFilters}
              sortASC={sortASC}
              setSortASC={setSortASC}
              identifier={identifier}
              sellerId={props.sellerId}
              refreshToken={props.refreshToken}
              vlookupObj={vlookupObj}
              setVlookupLoading={setVlookupLoading}
            />
          </>}
          {selectedStore === "walmart" && <>
            <WalmartFilterBar
              data={data}
              setFilters={setFilters}
              filters={filters}
              activePlanLevel={props.activePlanLevel}
              vlookupLoading={vlookupLoading}
              setVlookupLoading={setVlookupLoading}
            />
            <WalmartProductsTable
              data={filteredData}
              setData={setData}
              isMobile={props.isMobile}
              supplierType={"storefront"}
              hideCredits={props.hideCredits}
              showAdv={true}
              isProductsLoading={isProductsLoading}
              filters={filters}
              setFilters={setFilters}
              sortASC={sortASC}
              setSortASC={setSortASC}
              identifier={identifier}
              sellerId={props.sellerId}
              refreshToken={props.refreshToken}
              vlookupObj={vlookupObj}
              setVlookupLoading={setVlookupLoading}
            />
          </>}
        </>
      )}

      <Modal open={openModal} onClose={() => { setOpenModal(false); if (refresh) window.location.reload(); }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            {modalMsg}
          </Typography>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => { setOpenModal(false); if (refresh) window.location.reload(); }} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openAlertModal} onClose={() => setOpenAlertModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            {alertModalMsg}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => { setOpenAlertModal(false); navigate(navigatePage) }} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>

      <ColumnMappingModal
        open={openColumnMappingModal}
        onClose={() => setOpenColumnMappingModal(false)}
        headers={csvHeaders}
        onConfirm={handleColumnMappingConfirm}
        identifier={identifier}
        invalidColumns={invalidColumns}
        setInvalidColumns={setInvalidColumns}
      />
    </>
  );
}

const ColumnMappingModal = ({ open, onClose, headers, onConfirm, identifier, invalidColumns, setInvalidColumns }) => {
  const [mapping, setMapping] = useState({
    identifier: '',
    price: '',
    name: '',
    image: ''
  });
  const handleMappingChange = (field, value) => {
    setMapping(prev => ({
      ...prev,
      [field]: value
    }));
    setInvalidColumns(prev => ({
      ...prev,
      [field]: false
    }));
  };
  const handleSubmit = () => {
    if (mapping.identifier && mapping.price) {
      onConfirm(mapping);
    }
  };
  useEffect(() => {
    if (open) {
      const defaultMapping = {
        identifier: headers.find(h => {
          const lowerHeader = h.toLowerCase();
          switch(identifier) {
            case 'ASIN':
              return lowerHeader.includes('asin');
            case 'Barcode':
              return lowerHeader.includes('barcode') || lowerHeader.includes('upc') || lowerHeader.includes('ean');
            case 'Item ID':
              return lowerHeader.includes('item id') || lowerHeader.includes('itemid');
            default:
              return false;
          }
        }) || '',
        price: headers.find(h => h.toLowerCase().includes('price')) || '',
        name: headers.find(h => h.toLowerCase().includes('name')) || '',
        image: ''
      };
      setMapping(defaultMapping);
      setInvalidColumns({ identifier: false, price: false });
    }
  }, [open, headers, setInvalidColumns, identifier]);
  const getIdentifierErrorMessage = () => {
    switch(identifier) {
      case 'ASIN':
        return 'Invalid ASIN format. Must be 10 characters (letters and numbers).';
      case 'Barcode':
        return 'Invalid barcode format. Must be 8 or more digits.';
      case 'Item ID':
        return 'Invalid Item ID format. Must be 5 or more digits.';
      default:
        return 'Invalid identifier format.';
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '26vw',
        bgcolor: '#FCF8F3',
        borderRadius: 2,
        border: '4px solid rgba(126, 62, 27, 0.5)',
        boxShadow: 24,
        p: 4,
        outline: 'none',
      }}>
        <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center' }}>
          Map Your Columns
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth error={invalidColumns.identifier}>
            <InputLabel>Identifier Column ({identifier})</InputLabel>
            <Select
              value={mapping.identifier}
              label={`Identifier Column (${identifier})`}
              onChange={(e) => handleMappingChange('identifier', e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': invalidColumns.identifier ? {
                  borderColor: 'error.main',
                  borderWidth: '2px'
                } : {}
              }}
            >
              {headers.map((header) => (
                <MenuItem key={header} value={header}>{header}</MenuItem>
              ))}
            </Select>
            {invalidColumns.identifier && (
              <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                {getIdentifierErrorMessage()}
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth error={invalidColumns.price}>
            <InputLabel>Price Column</InputLabel>
            <Select
              value={mapping.price}
              label="Price Column"
              onChange={(e) => handleMappingChange('price', e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': invalidColumns.price ? {
                  borderColor: 'error.main',
                  borderWidth: '2px'
                } : {}
              }}
            >
              {headers.map((header) => (
                <MenuItem key={header} value={header}>{header}</MenuItem>
              ))}
            </Select>
            {invalidColumns.price && (
              <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                Invalid price format. Must be a valid number.
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Name Column (Optional)</InputLabel>
            <Select
              value={mapping.name}
              label="Name Column (Optional)"
              onChange={(e) => handleMappingChange('name', e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {headers.map((header) => (
                <MenuItem key={header} value={header}>{header}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Image Column (Optional)</InputLabel>
            <Select
              value={mapping.image}
              label="Image Column (Optional)"
              onChange={(e) => handleMappingChange('image', e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {headers.map((header) => (
                <MenuItem key={header} value={header}>{header}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!mapping.identifier || !mapping.price}
            style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#769358',
              borderRadius: '20px',
              border: '1px solid #769358'
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CSVScanner;
