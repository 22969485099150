import React from 'react';
import { Typography, Container, Button, ThemeProvider, createTheme, Box, Avatar, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AppsIcon from '@mui/icons-material/Apps';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import './HomePage.css';

const theme = createTheme({
  palette: {
    primary: { main: '#7e3e1b', },
  },
  typography: {
    fontFamily: 'Roboto Slab',
  },
});

const FeatureItem = ({ icon, title, text }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
    <Box className="iconPopup">
      {icon}
    </Box>
    <Typography variant="h6" sx={{ mt: 2, mb: 1, color: '#7e3e1b' }}>
      {title}
    </Typography>
    <Typography variant="body2" sx={{ color: '#a67b45' }}>
      {text}
    </Typography>
  </Box>
);

const HomePage = () => {
  const features = [
    {
      icon: <DataObjectIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Product Data",
      text: "Comprehensive details on each product, including specs, categories, and more."
    },
    {
      icon: <TrendingDownIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "BSR Drops 30|90 Days",
      text: "Track significant Best Seller Rank changes over the last 30 and 90 days."
    },
    {
      icon: <AppsIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Variations Data",
      text: "Analyze different product variations to identify top performers and opportunities."
    },
    {
      icon: <AttachMoneyIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Price",
      text: "Stay updated with real-time pricing for informed decision-making."
    },
    {
      icon: <ReceiptLongIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Fees",
      text: "Breakdown of all fees to accurately calculate your costs and margins."
    },
    {
      icon: <StorefrontIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Offers",
      text: "Monitor competitor offers and track changes in the marketplace."
    },
    {
      icon: <TrendingUpIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Profit",
      text: "Calculate potential profits based on current market conditions and your costs."
    },
    {
      icon: <ShowChartIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "ROI",
      text: "Evaluate Return on Investment to make data-driven inventory decisions."
    },
    {
      icon: <BarChartIcon sx={{ fontSize: 40, color: '#7e3e1b' }} />,
      title: "Estimated Sales/Month",
      text: "Forecast monthly sales volumes based on historical data and market trends."
    }
  ];

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ height: '80vh', backgroundColor: '#FCF8F3', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
        <Container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, }}>
          <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, }}>
            <Typography variant="h2" align="center" color="#7e3e1b">
              Do you sell Wholesale?
            </Typography>
            <Typography variant="h4" align="center" color="#7e3e1b">
              Amazon / Walmart CSV Scanner!
            </Typography>
          </Container>

          <video
            autoPlay
            loop
            muted
            poster="/assets/table_1.png"
            style={{ maxWidth: '70%', height: 'auto', mt: 2, border: '2px solid #7e3e1b', borderRadius: '20px' }}
          >
            <source
              src="/assets/test.mp4"
              type="video/mp4"
            />
          </video>
        </Container>
      </Box>

      {/* <Box sx={{ minHeight: '80vh', backgroundColor: '#F5E6D3', display: 'flex', flexDirection: 'column', justifyContent: 'center', py: 4, }}> */}
      <Box sx={{ minHeight: '60vh', padding: '2rem', backgroundColor: '#F5E6D3', display: 'flex', justifyContent: 'center', }}>
        <Grid container spacing={4} alignItems="center" sx={{ maxWidth: '70vw', }}>
          {/* Left Side: Image */}
          <Grid item xs={12} md={6}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                fill="#7e3e1b" stroke="none">
                <path d="M2075 2031 l-400 -127 -8 -69 c-6 -55 -5 -71 7 -77 11 -6 169 -121
                  185 -134 3 -2 -64 -98 -132 -188 -13 -18 -19 -16 -123 51 -179 115 -263 163
                  -282 163 -11 0 -65 -63 -152 -179 l-135 -178 -195 -7 c-107 -3 -212 -9 -232
                  -12 -37 -6 -38 -5 -38 23 0 25 -22 47 -155 162 -98 84 -161 131 -170 127 -34
                  -13 -12 -45 87 -131 97 -85 101 -89 77 -98 -149 -58 -236 -128 -261 -208 -12
                  -43 -19 -47 -38 -24 -7 9 -23 15 -34 13 -41 -6 -27 -35 48 -99 59 -50 77 -73
                  86 -104 24 -89 71 -211 102 -264 99 -167 315 -292 451 -261 43 9 87 48 87 76
                  0 12 7 13 38 7 66 -14 109 -9 137 17 14 13 25 29 25 37 0 7 9 13 19 13 24 0
                  78 44 86 70 4 11 15 20 26 20 32 0 56 17 67 49 14 39 6 68 -30 114 l-29 37 47
                  68 c26 37 63 88 81 113 l33 47 153 -84 c182 -101 172 -98 271 -61 55 21 63 28
                  145 138 47 63 124 172 171 242 47 69 89 126 93 127 5 0 56 -34 113 -75 l105
                  -75 42 14 c27 9 43 20 44 32 2 11 7 195 12 409 8 370 6 418 -18 414 -3 -1
                  -186 -58 -406 -128z m369 -273 c-4 -183 -9 -338 -13 -345 -4 -7 -45 20 -111
                  73 -58 46 -109 83 -115 81 -5 -2 -47 -57 -92 -123 -126 -182 -271 -386 -292
                  -412 l-19 -22 -192 115 c-106 63 -198 115 -204 115 -7 0 -68 -80 -136 -177
                  -68 -98 -125 -180 -126 -182 -4 -5 -176 123 -198 148 -23 25 -40 -2 198 313
                  l186 247 116 -69 c63 -39 148 -92 189 -120 41 -27 78 -50 83 -50 14 0 56 50
                  161 197 59 82 101 150 99 160 -2 10 -48 49 -103 86 -55 37 -101 71 -103 75 -1
                  4 150 55 335 114 186 58 339 107 340 107 2 1 0 -149 -3 -331z m-1924 -463 c0
                  -2 -16 -19 -36 -37 -25 -24 -33 -39 -28 -52 5 -14 13 -17 38 -12 93 20 233 36
                  358 42 159 8 152 15 81 -79 -25 -31 -49 -55 -54 -52 -16 11 -49 -6 -49 -24 0
                  -25 106 -124 238 -220 109 -81 132 -104 132 -135 0 -44 -70 -22 -170 53 -62
                  46 -63 47 -80 26 -17 -20 13 -53 93 -102 58 -36 66 -50 45 -71 -22 -22 -53
                  -12 -123 38 -77 55 -105 63 -105 28 0 -17 19 -36 70 -73 39 -27 70 -52 70 -56
                  0 -4 -7 -12 -15 -19 -25 -20 -101 -4 -163 35 -42 26 -59 32 -69 24 -20 -17
                  -16 -35 11 -56 23 -17 30 -32 27 -60 -2 -19 -70 -16 -116 6 -70 32 -164 111
                  -225 189 -89 114 -125 198 -155 355 -7 34 -36 36 -57 5 -13 -20 -17 -21 -32
                  -9 -30 25 -11 108 37 160 54 58 222 130 259 110 10 -5 18 -12 18 -14z"/>
                <path d="M900 1707 c0 -99 13 -136 44 -124 18 7 21 67 10 165 -6 46 -9 52 -30
52 -24 0 -24 -2 -24 -93z"/>
                <path d="M657 1683 c-16 -15 -5 -42 31 -77 50 -52 116 -96 141 -96 36 0 25 36
-22 68 -23 16 -60 48 -82 71 -39 40 -55 48 -68 34z"/>
                <path d="M1087 1616 c-50 -58 -53 -66 -35 -84 16 -16 38 -1 88 58 35 41 40 53
30 65 -20 24 -34 18 -83 -39z"/>
                <path d="M252 1428 c-17 -17 -15 -36 6 -47 25 -13 44 1 40 30 -3 27 -27 36
-46 17z"/>
              </g>
            </svg>
          </Grid>

          {/* Right Side: Text */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4">
              Wholesale Catalog Scanner
            </Typography>
            <br />
            <br />
            <Typography variant="span">
              Your supplier has provided you with a CSV file containing their product listings, and you need to determine which items to buy.
              Doing this manually, converting UPC codes and checking for potential profits, would be time-consuming.
            </Typography>
            <br /><br />
            <Typography variant="span">
              Aniseller's powerful data search quickly gets you the data you need to make informed buying decisions for your Amazon/Walmart business.
            </Typography>
            <br />
            <br />
            <Typography variant="body1">
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckIcon style={{ color: "#769358", fontSize: 28, }} />&nbsp;<strong>Powerful Data Filters</strong><br />
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckIcon style={{ color: "#769358", fontSize: 28, }} />&nbsp;<strong>Search by UPC, EAN, GTIN, ASIN or Walmart Item Id</strong><br />
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckIcon style={{ color: "#769358", fontSize: 28, }} />&nbsp;<strong>AI Matching</strong><br />
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ minHeight: '90vh', backgroundColor: '#FCF8F3', display: 'flex', flexDirection: 'column', justifyContent: 'center', py: 10, }}>
        <Container>
          <Typography variant="h3" align="center" color="#7e3e1b" gutterBottom>
            Quickly scan wholesale product lists for Amazon / Walmart
          </Typography>

          <Grid container spacing={4} sx={{ mt: 4 }}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FeatureItem {...feature} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <footer style={{ backgroundColor: '#7e3e1b', padding: 20 }}>
        <Typography variant="h6" align="center" gutterBottom style={{ color: '#F5E6D3' }}>
          Aniseller - Contact us: help@aniseller.com
        </Typography>
        <Typography variant="subtitle1" align="center" style={{ color: '#F5E6D3' }}>
          © 2025
        </Typography>
      </footer>
    </ThemeProvider>
  );
};

export default HomePage;